// Core
import React, { useEffect } from "react";
import { homepage } from "../MetaTags";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import Slider from "../components/Slider";
import ImgWhyDev from "../assets/images/why-devcontractor.jpeg";
import ImgSisterCompany from "../assets/images/sister-company.jpg";
import { Container, Col, Row } from "react-bootstrap";
import secImage1 from "../assets/images/carpentery-contracts.jpeg";
import secImage2 from "../assets/images/joinery-contractors.jpg";
import GetInTouch from "../components/GetInTouch";
import SliderProjects from "../components/SliderProjects";
import Service from "../components/Service";
import ScrollToTop from "react-scroll-to-top";
import Specialization from "../components/Specialization";
import ConstructionalOnlineLogo from '../assets/images/consturction_online.jpeg';
import SSIPLogo from '../assets/images/ssip-supplier.jpeg'

const Homepage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <MetaTags>
        <title>{homepage.title}</title>
        <meta name="description" content={homepage.description} />
        <link rel="canonical" href={homepage.canonical} />
      </MetaTags>
      <Slider />
      <section className="text-center  section-bg h1-tag">
        <Container>
          <h1 className="mt-5">
            Carpentry and Joinery Company in London - Dev Contractors Ltd.
          </h1>
          <div className="content-align">
            <p className="text-justify">
              We are a carpentry and joinery company. Designing, constructing,
              and fixing wooden structures is our area of expertise. We build
              everything from bespoke furniture and cabinets to the framing of
              houses and workplaces. Our business employs skilled workers with
              expertise in carpentry, joinery, and woodworking. Our bespoke
              interior fit-outs are our key selling point. We utilize different
              equipment and techniques to produce specialized, high-quality, and
              durable constructions that satisfy the requirements of our
              clients.
            </p>

            <p className="text-justify">
              We have worked on projects with various clients, including
              homeowners, contractors, architects, and designers. Over the past
              ten years, we have collaborated on several high-profile projects
              in and around London.
            </p>
          </div>
        </Container>
      </section>
      <section className="section-bg pt-0">
        <Container>
          <Row>
            <Col md={12} lg={6}>
              <div className="content h1-tag">
                <h1>Our Sister Company : Dev Patel Interiors Ltd.</h1>
                <p className="mt-3 text-justify">
                  At Dev Patel Interiors Ltd, we've been creating beautiful and
                  functional spaces for 10 years through high-quality carpentry
                  and joinery services. Our skilled craftsmen specialize in
                  designing and building bespoke wooden furniture, cabinetry,
                  and architectural elements. We're dedicated to excellence in
                  every project we take on, from the materials we use to the
                  techniques we employ.
                </p>
              </div>
            </Col>
            <Col md={12} lg={6} className="text-center mt-5">
              <img src={ImgSisterCompany} alt="" className="img-fluid bg" />
            </Col>
          </Row>
        </Container>
      </section>
      <section id="about" className="white-text">
        <Container>
          <Row className="bg-brown">
            <Col
              md={12}
              lg={6}
              className="d-flex justify-content-center"
              style={{ padding: "unset" }}
            >
              <img src={secImage1} alt="" className="img-fluid" />
            </Col>
            <Col md={12} lg={6} className="content">
              <h2>Carpentry Contractors in London</h2>
              <div>
                <p>
                  We offered carpentry services for many projects in the
                  construction industry, residences, offices, and other
                  buildings in London. Our group of expert carpenters are
                  capable of installing fire doors, kitchen fixtures, cubicle
                  systems, wall paneling, wood floors, and reception desks. We
                  delivered customized projects and built stunning functional
                  structures.
                </p>
              </div>
            </Col>
          </Row>
          <Row className="bg-brown">
            <Col md={12} lg={6} className="content">
              <h2>Joinery Contractors in London</h2>
              <div>
                <p>
                  Our team's specialties are installation and maintenance of
                  bespoke wooden structures to meet our client's requirements.
                  Based on our client's design requirements, we create bespoke
                  wooden fittings and structures. Our organization's personnel
                  are experienced, skilled, and highly qualified for joinery
                  projects. Our contractors have specialized training and
                  experience in woodworking.
                </p>
              </div>
            </Col>
            <Col
              md={12}
              lg={6}
              className="d-flex justify-content-center"
              style={{ padding: "unset" }}
            >
              <img src={secImage2} alt="About" className="img-fluid" />
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Service />
      </section>
      <section className="section-bg">
        <Container>
          <Row>
            <h2 className="text-center">Why Dev Contractors?</h2>
            <Col
              md={12}
              lg={6}
              className="text-center mt-5 d-flex justify-content-center"
            >
              <img src={ImgWhyDev} alt="" className="img-fluid bg" />
            </Col>
            <Col md={12} lg={6}>
              <div className="content mt-1">
                <p>
                  We have successfully worked in the sector for more than ten
                  years and have delivered the best bespoke interior fit-outs as
                  per the specifications of our clients. We specialize in
                  commercial fit-out, refurbishment of large/small projects,
                  commercial apartments, luxury residentials, hotels &
                  restaurants.
                </p>
                <p>
                  Our team of professionals perform exceptionally in interior
                  fit-outs, bespoke fit-outs, and first-fix and second-fix work.
                  We have always considered the individual needs and budget of
                  the client while using materials of the highest quality and
                  long-lasting.
                </p>
                <p>DevContractors Ltd. is a registered member of SSIP and a Gold member of ConstructionOnline.</p>


              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section style={{ paddingBottom: '20px', paddingTop: '20px' }}>
        <h2 className="d-flex justify-content-around mb-5">Our Accreditions </h2>
        <div className="d-flex justify-content-around mt-5">
          <img src={SSIPLogo} className="img-logo" alt="" />
          <img src={ConstructionalOnlineLogo} className="img-logo" alt="" />
        </div>
      </section>
      <section id="projects" style={{ background: "#2B2B2B" }}>
        <Container>
          <h2 className="text-center">Our Latest Projects</h2>
          <br />
          <SliderProjects />
        </Container>
      </section>
      <Specialization />
      <GetInTouch />
      <br />
      <br />
      <br />
      {/* <MissionAndVision /> */}
      <ScrollToTop smooth color="#ffffff" top={800} />
    </div>
  );
};

export default Homepage;
