import React, { useEffect } from "react";
import { Button, Carousel } from "react-bootstrap";
import { Container, Col, Row } from "react-bootstrap";
import ScrollAnimation from "react-animate-on-scroll";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MetaTags from "react-meta-tags";
import GetInTouch from "../components/GetInTouch";
import MissionImg from "../assets/images/icons/mission.png";
import VisionImg from "../assets/images/icons/vision.png";
import ImgWhyDev from "../assets/images/why-devcontractor.jpeg";
import Slider1 from "../assets/images/about-us-brdcms.jpg";
import { aboutUs } from "../MetaTags";
import ScrollToTop from "react-scroll-to-top";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";
import ConstructionalOnlineLogo from '../assets/images/consturction_online.jpeg';
import SSIPLogo from '../assets/images/ssip-supplier.jpeg'
const AboutUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <MetaTags>
        <title>{aboutUs.title}</title>
        <meta name="description" content={aboutUs.description} />
        <link rel="canonical" href={aboutUs.canonical} />
      </MetaTags>
      <div id="breadCrumb">
        <Carousel controls={false} interval={null} indicators={false}>
          <Carousel.Item className="relative">
            <img className="d-block w-100 img-fluid" src={Slider1} alt="" />
            <Carousel.Caption className="absolute-center">
              <div id="breadcrumb-heading">About Us</div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
      <section className="section-bg">
        <Container>
          <Row>
            <Col
              lg={6}
              md={12}
              className="text-center d-flex flex-column justify-content-center"
              style={{ marginTop: "3.875rem" }}
            >
              <img src={ImgWhyDev} alt="" className="img-fluid bg" />
            </Col>
            <Col lg={6} md={12}>
              <div className="content h1-tag">
                <h1>
                  Dev Contractors : Carpenters & Joinery Contractors in London
                </h1>
                <ScrollAnimation animateIn="fadeInUp">
                  <p>
                    Dev Contractors Ltd is a carpentry and joinery company that
                    specializes in designing, constructing, and fixing wooden
                    structures. Founded by Devendra Patel with more than 10
                    years of industry experience, we have been supplying quality
                    carpenters and joiners to the construction industry for over
                    10 years.
                  </p>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUp">
                  <p>
                    Our skilled workforce takes pride in their work and is
                    committed to providing the best quality across all main
                    construction sectors in and around the capital. We build
                    everything from bespoke furniture and cabinets to the
                    framing of houses and workplaces. Our hands-on approach
                    allows us to continue growing our portfolio of clients,
                    delivering service, quality, and value on a vast number of
                    prestigious projects in and around the London area.
                  </p>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUp">
                  <p>
                    Our team includes a number of trusted suppliers, craftsmen,
                    and subcontractors, all of whom adhere to our high standard
                    of quality.We specialize in commercial fit-out,
                    refurbishment of large/small projects, commercial
                    apartments, luxury residentials, hotels, and restaurants.
                  </p>
                </ScrollAnimation>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section style={{ paddingBottom: '20px', paddingTop: '20px' }}>
        <h2 className="d-flex justify-content-around mb-5">Our Accreditions </h2>
        <div className="d-flex justify-content-around mt-5">
          <img src={SSIPLogo} className="img-logo" alt="" />
          <img src={ConstructionalOnlineLogo} className="img-logo" alt="" />
        </div>
        <hr className="mt-4" />
      </section>
      <ScrollAnimation animateIn="fadeInUp">
        <section>
          <Container>
            <Col md={12}>
              <div className="service-container">
                <div>
                  <img
                    src={VisionImg}
                    alt=""
                    style={{ width: "400px" }}
                    className="img-fluid relative"
                  />
                </div>
                <div className="abt-right-div">
                  <h2 className="mb-3">VISION</h2>
                  <h5>Quality Install</h5>
                  <p>
                    We prioritize providing our customers with high-quality
                    structures and interiors.
                  </p>
                  <h5>On Programme</h5>
                  <p>
                    We emphasize producing high-quality work and achieving
                    objectives within the timeframe.
                  </p>
                  <h5>On Budget</h5>
                  <p>
                    We ensure that projects are finished under budget while
                    achieving the desired outcomes.
                  </p>
                  <h5>Snag Free</h5>
                  <p>
                    We make sure to achieve close out of snags as works finish
                    for a smooth transition to handover.
                  </p>
                </div>
              </div>
            </Col>
            <Col md={12}>
              <div className="service-container mt-5">
                <div className="abt-left-div">
                  <h2 className="mb-3 ">MISSION</h2>
                  <p>
                    We hope to contribute to the expansion and improvement of
                    the communities we serve through our work as well as
                    establish long-lasting relationships with our customers that
                    are founded on respect, trust, and mutual benefit.
                    <br /> We are dedicated to delivering top-notch work and
                    developing enduring relationships with our clients.
                  </p>
                </div>
                <div>
                  <img
                    src={MissionImg}
                    alt=""
                    style={{ width: "850px" }}
                    className="img-fluid relative"
                  />
                </div>
              </div>
            </Col>
          </Container>
        </section>
      </ScrollAnimation>
      <ScrollAnimation animateIn="fadeInUp">
        <section className="experience bg-clients flex-center mt-5">
          <Container>
            <h2 className="mb-5">We have Done</h2>
            <div className="experience-inner">
              <div>
                <h3>10+</h3>
                <h3>
                  <span>
                    Years of <br /> Experience
                  </span>
                </h3>
              </div>

              <div>
                <h3>50+</h3>
                <h3>
                  <span>&nbsp; &nbsp;Projects &nbsp; &nbsp;</span>
                </h3>
              </div>

              <div>
                <h3>15+</h3>
                <h3>
                  <span> &nbsp; &nbsp;Clients &nbsp; &nbsp;</span>
                </h3>
              </div>
            </div>
          </Container>
        </section>
      </ScrollAnimation>
      <br />
      <br />
      <br />
      <GetInTouch />
      <br />
      <br />
      <br />
      <ScrollToTop smooth color="#ffffff" top={800} />
    </div>
  );
};

export default AboutUs;
