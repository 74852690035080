import React, { useEffect } from "react";
import { contactUs } from "../MetaTags";
import MetaTags from "react-meta-tags";
import { Container, Col, Row } from "react-bootstrap";
import RoomIcon from "@mui/icons-material/Room";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import ContactForm from "../components/ContactForm";

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <MetaTags>
        <title>{contactUs.title}</title>
        <meta name="description" content={contactUs.description} />
        <link rel="canonical" href={contactUs.canonical} />
      </MetaTags>
      <section style={{ background: "#F4F4F4" }} className="entry">
        <Container>
          <Row>
            <Col md={4} xs={12} className="contact-info my-auto">
              {/* <hr />
                            <div>
                                <h4><PhoneIcon/>Call</h4>
                            </div>
                            <p>
                                <a href="tel:+44 7791092574">+44 7791092574</a>
                            </p>
                            <br />
                            <div>
                                <h4><EmailIcon />Email</h4>
                            </div>
                            <p>
                                <a href="mailto:info@devcontractors.co.uk">info@devcontractors.co.uk</a>
                            </p>
                            <br />
                            <div>
                                <h4><RoomIcon />Address</h4>
                                <p>337 Athlon Road, Wembley, <br /> England, HA01EF</p>
                            </div>
                            <br /> */}
              <h2>Get In Touch</h2>
              <div className="icon">
                <RoomIcon />
              </div>
              <h4>Address</h4>
              <p>
                337 Athlon Road, Wembley, <br /> England, HA01EF
              </p>
              <div className="icon ">
                <PhoneIcon />
              </div>
              <h4>Phone Number</h4>
              <p>
                <a href="tel:+44 7791092574">+44 7791092574</a>
              </p>
              <p></p>
              <div className="icon">
                <EmailIcon />
              </div>
              <h4>Email</h4>
              <p>
                <a href="mailto:info@devcontractors.co.uk">
                  info@devcontractors.co.uk
                </a>
              </p>
            </Col>
            <Col md={8}>
              <ContactForm />
            </Col>
          </Row>
        </Container>
      </section>
      <br />
      <section className="map mb-5 mt-5">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2481.6586643516266!2d-0.30643872283798906!3d51.5378201718202!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876123fb073a099%3A0x679b63abc406fa9f!2s337%20Athlon%20Rd%2C%20Wembley%20HA0%201EF%2C%20UK!5e0!3m2!1sen!2sin!4v1680600574615!5m2!1sen!2sin"
          title={"Map Location"}
          width="100%"
          height="380"
          frameborder="0"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </section>
      <br />
      <br />
    </div>
  );
};

export default ContactUs;
