import React from "react";
import DoorIcon from "../assets/images/icons/fire-door.png";
import CommercialIcon from "../assets/images/icons/commercial.png";
import WallPanelingIcon from "../assets/images/icons/wall-paneling.png";
import { Container, Col, Row } from "react-bootstrap";
import cubicleIcon from "../assets/images/icons/cubicle-system.png";
import ironmongeryIcon from "../assets/images/icons/ironmongery.png";
import KitchenFittingIcon from "../assets/images/icons/kitchen-fitting.png";
import ReceptionIcon from "../assets/images/icons/reception-desk.png";

const Service = () => {
  const imgSet = {
    width: "40px",
    marginRight: "20px",
  };
  return (
    <div>
      <section className="mt-5">
        <Container>
          <div>
            <h2 className="text-center">Our Services</h2>
          </div>
          <div className="service-card mt-5">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">
                  <img src={KitchenFittingIcon} style={imgSet} alt="" />
                  KITCHEN FITTING
                </h5>
                <p class="card-text mt-3">
                  Design and creation of kitchen cabinets, shelves, and other
                  fixtures. We have qualified and experienced workers who can
                  design a precise, intricate, and imaginative kitchen.
                </p>
                <p></p>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">
                  <img src={WallPanelingIcon} style={imgSet} alt="" />
                  WALL PANELING
                </h5>
                <p class="card-text mt-3">
                  Installation of decorative or functional panels on walls. Our
                  wall paneling service includes using carpentry tools and
                  methods to measure, cut, and install decorative or functional
                  panels on walls.
                </p>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">
                  <img src={ironmongeryIcon} style={imgSet} alt="" />
                  DOOR SET & IRONMONGERY
                </h5>
                <p class="card-text mt-3">
                  The installation of complete door units, including hardware
                  and ironmongery Fitting door sets and ironmongery is
                  installation of complete door units, comprising the frame,
                  door, and all necessary hardware.
                </p>
              </div>
            </div>
          </div>

          <div className="service-card mt-5">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">
                  <img src={DoorIcon} style={imgSet} alt="" />
                  FIRE DOOR INSTALLATION
                </h5>
                <p class="card-text mt-3">
                  The proper placement and securing of specialized fire doors.
                  The process of installing fire doors to aid in fire prevention
                  is known as fire door installation.
                </p>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">
                  <img src={ReceptionIcon} style={imgSet} alt="" />
                  RECEPTION DESK
                </h5>
                <p class="card-text mt-3">
                  We design, build, and install reception desks in commercial
                  spaces. Our personnel put attention to detail and creativity
                  to create a functional and visually appealing reception area.
                </p>
              </div>
            </div>
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">
                  <img src={cubicleIcon} style={imgSet} alt="" />
                  CUBICLE SYSTEMS
                </h5>
                <p class="card-text mt-3">
                  Installation and customization of modular office partitions.
                  Modular office walls and cubicles are designed, installed, and
                  customized during the process.
                </p>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </div>
  );
};

export default Service;
