import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import List from "@mui/material/List";
import MetaTags from "react-meta-tags";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link, useLocation } from "react-router-dom";
import Logo from "../assets/images/logo/devcontractor-logo.png";
import { menu } from "../Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import CloseIcon from "@mui/icons-material/Close";
import { MailOutline, Phone, VerifiedRounded } from "@mui/icons-material";

const Header = () => {
  let location = useLocation().pathname;
  const [sideMenu, setSideMenu] = useState(false);

  function checkUrl(url) {
    if (location === url) {
      return "active";
    } else {
      return null;
    }
  }

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setSideMenu({ sideMenu, left: open });
  };

  const handleLoaded = (_) => {
    window.grecaptcha.ready((_) => {
      window.grecaptcha
        .execute("6LeKvqYlAAAAAPXB1LqKgduRLY__hbiQ11qUSwa_", {
          action: "homepage",
        })
        .then((token) => {
          // ...
          console.log("Verified");
        });
    });
  };

  useEffect(() => {
    // Add reCaptcha
    const script = document.createElement("script");
    script.src =
      "https://www.google.com/recaptcha/api.js?render=6LeKvqYlAAAAAPXB1LqKgduRLY__hbiQ11qUSwa_";
    script.addEventListener("load", handleLoaded);
    document.body.appendChild(script);
  }, []);
  return (
    <div id="header">
      <div className="top-bar">
        <p>
          <a
            style={{ marginRight: "40rem" }}
            href="mailto:info@devcontractors.co.uk"
            target="_blank"
            rel="noopener noreferrer"
          >
            <MailOutline />
            info@devcontractors.co.uk
          </a>
        </p>
        <p>
          <a
            href="tel:+44 7791092574"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Phone />
            +44 7791092574
          </a>
        </p>
      </div>
      <Container>
        {/* Mobile Menu */}
        <SwipeableDrawer
          open={sideMenu["left"]}
          onClose={toggleDrawer(false)}
          onOpen={toggleDrawer(true)}
        >
          <Box
            sx={{ width: "96vw" }}
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
          >
            <div className="mb-5 pb-5" />
            <Divider />
            <List>
              {menu.map((menu, index) => (
                <Link to={menu.path}>
                  <ListItem button key={index}>
                    <ListItemIcon>
                      <KeyboardReturnIcon className="fa-flip-horizontal" />
                    </ListItemIcon>
                    <ListItemText primary={menu.label} />
                  </ListItem>
                </Link>
              ))}
            </List>
          </Box>
        </SwipeableDrawer>
        {/* END: Mobile Menu */}
        <Link to="/">
          <img src={Logo} alt="Logo" className="img-fluid pt-2 pb-2 logo" />
        </Link>
        <Button
          onClick={sideMenu["left"] ? toggleDrawer(false) : toggleDrawer(true)}
          className="d-block d-sm-none menuicon"
        >
          {sideMenu["left"] ? <CloseIcon /> : <MenuIcon />}
        </Button>
        <nav id="navbar" className="navbar right d-none d-sm-block">
          <ul>
            {menu.map((menu, index) => {
              return (
                <li key={index} className={checkUrl(menu.path)}>
                  <Link to={menu.path}>{menu.label}</Link>
                </li>
              );
            })}
          </ul>
        </nav>
      </Container>
      <div
        className="g-recaptcha"
        data-sitekey="6LeKvqYlAAAAAPXB1LqKgduRLY__hbiQ11qUSwa_"
        data-size="invisible"
      ></div>
    </div>
  );
};

export default Header;
