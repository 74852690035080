import React, { useEffect } from "react";
import { Button, Carousel } from "react-bootstrap";
import MetaTags from "react-meta-tags";
import ScrollAnimation from "react-animate-on-scroll";
import { Container, Col, Row } from "react-bootstrap";
import GetInTouch from "../components/GetInTouch";
import ContactForm from "../components/ContactForm";
import Breadcrumb from "../assets/images/service-brdcms.jpg";
import Service1 from "../assets/images/services/service1.jpg";
import Service2 from "../assets/images/services/service2.jpg";
import Service3 from "../assets/images/services/service3.jpg";
import Service4 from "../assets/images/services/service4.jpg";
import Service5 from "../assets/images/services/service5.jpg";
import Service6 from "../assets/images/services/service6.jpg";
import Service7 from "../assets/images/services/service7.jpg";
import Service8 from "../assets/images/services/service8.jpg";
import { services } from "../MetaTags";
import ScrollToTop from "react-scroll-to-top";

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <MetaTags>
        <title>{services.title}</title>
        <meta name="description" content={services.description} />
        <link rel="canonical" href={services.canonical} />
      </MetaTags>
      <div id="breadCrumb">
        <Carousel controls={false} interval={null} indicators={false}>
          <Carousel.Item className="relative">
            <img className="d-block w-100 img-fluid" src={Breadcrumb} alt="" />
            <Carousel.Caption className="absolute-center">
              <div id="breadcrumb-heading">Services</div>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
      <section id="service">
        <Container fluid className="text-center mt-5 h1-tag">
          <h1>Best Carpentry and Joinery Services in London</h1>
          <Col md={12}>
            <div className="service-container mt-10">
              <div className="left-div">
                <img src={Service1} alt="" className="img-fluid relative" />
              </div>

              <div className="right-div content">
                <h2>Bespoke Interior Fitout</h2>
                <h5>Designing and creating customized interior spaces</h5>
                <p>
                  We use specialized furnishings, fittings, and fixtures, as
                  well as one-of-a-kind features and components to create
                  stunning and distinctive spaces. We collaborate in a variety
                  of settings, including restaurants, businesses, and luxury
                  residences. Our goal is to design a place that not only serves
                  the client's needs and is aesthetically beautiful, but also
                  reflects their personality and sense of style.
                </p>
              </div>
            </div>

            <ScrollAnimation animateIn="fadeInUp">
              {" "}
              <div className="service-container mt-10">
                <div className="right-divLeft">
                  <h2>Reception Desk</h2>
                  <h5>
                    We design, build, and install reception desks in commercial
                    spaces.
                  </h5>
                  <p>
                    Our personnel put attention to detail and creativity to
                    create a functional and visually appealing reception area.
                    We create reception desks that make a positive impression on
                    clients and visitors. We use high-quality material to ensure
                    the quality of assembly and installation of the finished
                    product. Reception desk carpentry work contributes to the
                    overall aesthetic and functionality of a space.
                  </p>
                </div>
                <div className="left-div">
                  <img src={Service6} alt="" className="img-fluid relative" />
                </div>
              </div>
            </ScrollAnimation>
          </Col>

          <Col md={12}>
            <ScrollAnimation animateIn="fadeInUp">
              <div className="service-container mt-10">
                <div className="left-div">
                  <img src={Service5} alt="" className="img-fluid relative" />
                </div>

                <div className="right-div">
                  <h2>Kitchen Fitting</h2>
                  <h5>
                    Design and Creation of kitchen cabinets, shelves, and other
                    fixtures
                  </h5>
                  <p>
                    We have qualified and experienced workers who can design a
                    precise, intricate, and imaginative kitchen. We work hard to
                    provide functional and utilitarian kitchen desks. Kitchen
                    fitting carpentry work is an important aspect of interior
                    design and contributes to overall functionality, style, and
                    value of a home.
                  </p>
                </div>
              </div>
            </ScrollAnimation>

            <ScrollAnimation animateIn="fadeInUp">
              <div className="service-container mt-10">
                <div className="right-divLeft">
                  <h2>Wall Paneling</h2>
                  <h5>
                    Installation of decorative or functional panels on walls
                  </h5>
                  <p>
                    Our wall paneling service includes using carpentry tools and
                    methods to measure, cut, and install decorative or
                    functional panels on walls. The panels are correctly placed
                    and firmly fastened to the wall surface thanks to the
                    expertise of our workers. We ensure materials we use for
                    wall paneling are durable and of excellent quality.
                  </p>
                </div>
                <div className="left-div">
                  <img src={Service7} alt="" className="img-fluid relative" />
                </div>
              </div>
            </ScrollAnimation>
          </Col>

          <Col md={12}>
            <ScrollAnimation animateIn="fadeInUp">
              <div className="service-container mt-10">
                <div className="left-div">
                  <img src={Service8} alt="" className="img-fluid relative" />
                </div>

                <div className="right-div">
                  <h2>Wood Flooring</h2>
                  <h5>
                    Installation of wooden planks on the floor to create
                    aesthetically pleasing surface
                  </h5>
                  <p>
                    Wood flooring involves the installation of wood planks or
                    boards on a subfloor, using specialized carpentry tools and
                    techniques to create a durable and visually appealing
                    surface for interior spaces. The process includes measuring,
                    cutting, and laying the wood flooring, sanding, and
                    finishing the surface to create a smooth and even finish.
                  </p>
                </div>
              </div>
            </ScrollAnimation>

            <ScrollAnimation animateIn="fadeInUp">
              <div className="service-container mt-10">
                <div className="right-divLeft">
                  <h2>Cubicle Systems</h2>
                  <h5>
                    Installation and customization of modular office partitions
                  </h5>
                  <p>
                    Modular office walls and cubicles are designed, installed,
                    and customized during the process. We employ specialists
                    with training in carpentry and expertise in modular systems.
                    We design the system to meet the unique requirements of the
                    workspace, such as including electrical outlets or storage
                    spaces in the plan. The final product maximizes productivity
                    and employee privacy.
                  </p>
                </div>
                <div className="left-div">
                  <img src={Service2} alt="" className="img-fluid relative" />
                </div>
              </div>
            </ScrollAnimation>
          </Col>
        </Container>
        <br />
        <br />
        <br />
        <br />
        <GetInTouch />
        <Container fluid>
          <Col md={12}>
            <ScrollAnimation animateIn="fadeInUp">
              <div className="service-container mt-10">
                <div className="left-div">
                  <img src={Service4} alt="" className="img-fluid relative" />
                </div>

                <div className="right-div">
                  <h2>Fire Door Installation</h2>
                  <h5>
                    The proper placement and securing of specialized fire doors
                  </h5>
                  <p>
                    The process of installing fire doors to aid in fire
                    prevention is known as fire door installation. Fire doors
                    withstand flames for a specific period of time, providing
                    building inhabitants time to escape in case of a fire. We
                    guarantee proper fire door installation, choosing the right
                    door for the situation, and making sure the door is properly
                    fixed in the frame.
                  </p>
                </div>
              </div>
            </ScrollAnimation>

            <ScrollAnimation animateIn="fadeInUp">
              {" "}
              <div className="service-container mt-10">
                <div className="right-divLeft">
                  <h2>Door Sets & Ironmongery Fitting</h2>
                  <h5>
                    The installation of complete door units, including hardware
                    and ironmongery
                  </h5>
                  <p>
                    Fitting door sets and ironmongery is installation of
                    complete door units, comprising the frame, door, and all
                    necessary hardware, such as hinges, handles, locks, and
                    other ironmongery. We ensure that the door set is level,
                    properly positioned, and firmly fastened into the aperture
                    and that all hardware is appropriately placed and operating.
                  </p>
                </div>
                <div className="left-div">
                  <img src={Service3} alt="" className="img-fluid relative" />
                </div>
              </div>
            </ScrollAnimation>
          </Col>
        </Container>
        <Container>
          <ScrollAnimation animateIn="fadeInUp">
            <div className="text-center mt-10">
              <h2>Make an Enquiry</h2>
              <p>
                If you’d like to discuss your next project, or just want to find
                out more about Dev Contractors Ltd. and our <br />
                work, please get in contact with us by filling this form
              </p>
            </div>
          </ScrollAnimation>

          <Col md={8} className="col-aligncenter mt-5 mb-5">
            <ContactForm />
          </Col>
        </Container>
      </section>
      <ScrollToTop smooth color="#ffffff" top={800} />
    </div>
  );
};

export default Services;
