import React, { useState, useEffect } from "react";
import { Container, Col, Row } from "react-bootstrap";
import { useTheme } from "@mui/material/styles";
import ImageList from "@mui/material/ImageList";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button, Carousel } from "react-bootstrap";
import { projectGallery } from "../MetaTags";
import { MetaTags } from "react-meta-tags";
import ImageListItem from "@mui/material/ImageListItem";
import useMediaQuery from "@mui/material/useMediaQuery";
import Img1 from "../assets/images/projects/project1.jpg";
import Img2 from "../assets/images/projects/project2.jpg";
import Img3 from "../assets/images/projects/project3.jpg";
import Img4 from "../assets/images/projects/project4.jpg";
import Img5 from "../assets/images/projects/project5.jpg";
import Img6 from "../assets/images/projects/project6.jpg";
import Img7 from "../assets/images/projects/project7.jpg";
import Img8 from "../assets/images/projects/project8.jpg";
import Img9 from "../assets/images/projects/project9.jpg";
import Img10 from "../assets/images/projects/project10.jpg";
import Img11 from "../assets/images/projects/project11.jpg";
// import Img12 from "../assets/images/projects/project12.jpg";
// import Img13 from "../assets/images/projects/project13.jpg";
import Img14 from "../assets/images/projects/project14.jpg";
import Img15 from "../assets/images/projects/project15.jpg";
import Img16 from "../assets/images/projects/project16.jpg";
import Img17 from "../assets/images/projects/project17.jpg";
// import Img18 from "../assets/images/projects/project18.jpg";
import Img19 from "../assets/images/projects/project-prt1.jpg";
import Img20 from "../assets/images/projects/project-prt2.jpg";
import Img21 from "../assets/images/projects/project-prt3.jpg";
import Img22 from "../assets/images/projects/project-prt4.jpg";
// import Img23 from "../assets/images/projects/project-prt5.jpg";
// import Img24 from "../assets/images/projects/project-prt6.jpg";
import Img25 from "../assets/images/projects/project-prt7.jpg";
// import Img26 from "../assets/images/projects/project-prt8.jpg";
// import Img27 from "../assets/images/projects/project-prt9.jpg";
import Img28 from "../assets/images/projects/project-prt10.jpg";
import Img29 from "../assets/images/projects/project-prt11.jpg";
import Img30 from "../assets/images/projects/project-prt13.jpg";
import Img31 from "../assets/images/projects/project-prt14.jpg";
import Img32 from "../assets/images/projects/project-prt15.jpg";
import Img33 from "../assets/images/projects/project-prt16.jpg";
import Img34 from "../assets/images/projects/project-prt17.jpg";
import Img35 from "../assets/images/projects/project19.jpg";
import Img36 from "../assets/images/projects/project-prt19.jpg";
import Img37 from "../assets/images/projects/project-prt20.jpg";
import Img38 from "../assets/images/projects/project-prt21.jpg";
import Img39 from "../assets/images/projects/project-prt22.jpg";
import Img40 from "../assets/images/projects/project-prt23.jpg";
import Img41 from "../assets/images/projects/project-prt23.jpeg";
import Img42 from "../assets/images/projects/project-prt24.jpeg";
import Img43 from "../assets/images/projects/project-prt25.jpeg";
import Img44 from "../assets/images/projects/project-prt26.jpeg";
import Img45 from "../assets/images/projects/project-prt27.jpeg";
import Img46 from "../assets/images/projects/project-prt28.jpeg";
import Img47 from "../assets/images/projects/project-prt29.jpeg";
import Img48 from "../assets/images/projects/project-prt30.jpeg";




import GetInTouch from "../components/GetInTouch";
import Slider1 from "../assets/images/project-gallery-brdcms.jpg";
import ScrollToTop from "react-scroll-to-top";

const OurWorks = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const theme = useTheme();
  const matchDownSm = useMediaQuery(theme.breakpoints.down("sm"));
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    boxShadow: 24,
  };

  const itemData = [
    {
      img: Img1,
      title: "Arsenal Football Stadium",
      author: "author",
      cols: 1,
    },
    {
      img: Img2,
      title: "Finsbury Park Hotel",
      author: "author",
      cols: 1,
    },
    {
      img: Img3,
      title: "Metropolitan Housing Association Enfield Council",
      author: "author",
      cols: 1,
    },
    {
      img: Img4,
      title: "Twickenham Rugby Stadium",
      author: "author",
      cols: 1,
    },
    {
      img: Img5,
      title: "Image",
      author: "author",
      cols: 1,
    },
    {
      img: Img6,
      title: "Image",
      author: "author",
      cols: 1,
    },
    {
      img: Img7,
      title: "Image",
      author: "author",
      cols: 1,
    },
    {
      img: Img8,
      title: "Image",
      author: "author",
      cols: 1,
    },
    {
      img: Img9,
      title: "Image",
      author: "author",
      cols: 1,
    },
    {
      img: Img10,
      title: "Image",
      author: "author",
      cols: 1,
    },
    {
      img: Img11,
      title: "Image",
      author: "author",
      cols: 1,
    },
    // {
    //   img: Img12,
    //   title: "Image",
    //   author: "author",
    //   cols: 1,
    // },
    // {
    //   img: Img13,
    //   title: "Image",
    //   author: "author",
    //   cols: 1,
    // },
    {
      img: Img14,
      title: "Image",
      author: "author",
      cols: 1,
      height: 4,
    },
    // {
    //   img: Img15,
    //   title: "Image",
    //   author: "author",
    //   cols: 1,
    // },
    {
      img: Img41,
      title: "Image",
      author: "author",

      cols: 1,
    },
    {
      img: Img42,
      title: "Image",
      author: "author",

      cols: 1,
    },
    {
      img: Img46,
      title: "Image",
      author: "author",

      cols: 1,
    },
    {
      img: Img47,
      title: "Image",
      author: "author",

      cols: 1,
    },
    {
      img: Img16,
      title: "Image",
      author: "author",
      cols: 1,
    },
    {
      img: Img17,
      title: "Image",
      author: "author",
      cols: 1,
    },
    // {
    //   img: Img18,
    //   title: "Image",
    //   author: "author",
    //   cols: 1,
    // },
    {
      img: Img19,
      title: "Image",
      author: "author",
      cols: 1,
    },
    {
      img: Img20,
      title: "Image",
      author: "author",
      cols: 1,
    },
    {
      img: Img21,
      title: "Image",
      author: "author",

      cols: 1,
    },
    {
      img: Img22,
      title: "Image",
      author: "author",
      cols: 1,
    },
    // {
    //   img: Img23,
    //   title: "Image",
    //   author: "author",

    //   cols: 1,
    // },
    // {
    //   img: Img24,
    //   title: "Image",
    //   author: "author",

    //   cols: 1,
    // },
    {
      img: Img25,
      title: "Image",
      author: "author",

      cols: 1,
    },
    // {
    //   img: Img26,
    //   title: "Image",
    //   author: "author",

    //   cols: 1,
    // },
    // {
    //   img: Img27,
    //   title: "Image",
    //   author: "author",

    //   cols: 1,
    // },
    {
      img: Img29,
      title: "Image",
      author: "author",

      cols: 1,
    },
    {
      img: Img28,
      title: "Image",
      author: "author",

      cols: 1,
    },
    {
      img: Img30,
      title: "Image",
      author: "author",
      cols: 1,
    },
    {
      img: Img31,
      title: "Image",
      author: "author",

      cols: 1,
    },
   
    {
      img: Img32,
      title: "Image",
      author: "author",

      cols: 1,
    },
    {
      img: Img48,
      title: "Image",
      author: "author",

      cols: 1,
    },
    {
      img: Img33,
      title: "Image",
      author: "author",

      cols: 1,
    },
    {
      img: Img34,
      title: "Image",
      author: "author",

      cols: 1,
    },
     {
      img: Img35,
      title: "Image",
      author: "author",

      cols: 1,
    },
     {
      img: Img36,
      title: "Image",
      author: "author",

      cols: 1,
    },
    {
      img: Img37,
      title: "Image",
      author: "author",

      cols: 1,
    },
     {
      img: Img38,
      title: "Image",
      author: "author",

      cols: 1,
    },
     {
      img: Img39,
      title: "Image",
      author: "author",

      cols: 1,
    },
    {
      img: Img40,
      title: "Image",
      author: "author",

      cols: 1,
    },
   
    {
      img: Img43,
      title: "Image",
      author: "author",

      cols: 1,
    },
    {
      img: Img44,
      title: "Image",
      author: "author",

      cols: 1,
    },
    {
      img: Img45,
      title: "Image",
      author: "author",

      cols: 1,
    },
   
   

  ];

  const [value, setValue] = useState(0);
  const [finalImage, setFinalImage] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const handleOpen = (image) => {
    setFinalImage(image);
    setOpen(true);
  };

  return (
    <div>
      <MetaTags>
        <title>{projectGallery.title}</title>
        <meta name="description" content={projectGallery.description} />
        <link rel="canonical" href={projectGallery.canonical} />
      </MetaTags>
      <div id="breadCrumb">
        <Carousel controls={false} interval={null} indicators={false}>
          <Carousel.Item className="relative">
            <img className="d-block w-100 img-fluid" src={Slider1} alt="" />
            <Carousel.Caption className="absolute-center">
              <h1>Our Works</h1>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
      <section className="gallery mt-5">
        <Container>
          <h2 className="text-center mb-5">Our Works</h2>
          <ImageList
            variant="masonry"
            cols={matchDownSm ? 1 : matchDownMd ? 2 : 3}
            // cols={3}
            gap={10}
          >
            {itemData.map((item) => (
              <ImageListItem key={item.img}>
                <img
                  onClick={() => handleOpen(item.img)}
                  src={`${item.img}?w=248&fit=crop&auto=format`}
                  srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                  alt={item.title}
                  // className="img-fluid pl-3 pr-2"
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </Container>
      </section>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <img src={finalImage} alt="Gallery" className="img-fluid" />
        </Box>
      </Modal>
      <GetInTouch />
      <br />
      <br />
      <br />
      <ScrollToTop smooth color="#ffffff" top={800} />
    </div>
  );
};

export default OurWorks;
