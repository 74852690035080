import React from "react";
import { Button, Carousel } from "react-bootstrap";
import { Container, Col, Row } from "react-bootstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Special_1 from "../assets/images/specialization1.jpg";
import Special_2 from "../assets/images/specialization2.jpg";
import Special_3 from "../assets/images/specialization3.jpg";
import Special_4 from "../assets/images/speacialization4.jpg";
import Special_5 from "../assets/images/speacialization5.jpg";
import Special_6 from "../assets/images/speacialization6.jpg";
import Arwimg from "../assets/images/arrow1.png";

const Specialization = () => {
  const specializationCards = [
    {
      img: Special_1,
      title: "First & Second Fix",
      points: [
        "Drywall - suspension ceiling",
        "Sash window repairing and installing",
        "Timber flooring",
        "Fire Door installation & maintenance (remedial work)",
        "Timber stud partitions",
      ],
    },
    {
      img: Special_2,
      title: "Interior Fitout",
      points: ["Door Sets", "Cubicle systems", "Wood flooring", "Ironmongery"],
    },
    {
      img: Special_3,
      title: "Bespoke Fit Out",
      points: [
        "Receptions desks",
        "Wall panelling",
        "Kitchens",
        "Acoustic panelling",
        "Wardrobes",
      ],
    },
    {
      img: Special_4,
      title: "Construction Sector",
      points: [
        "Residential (luxury apartment projects, private and student accommodation)",
        "Commercial",
        "Health",
        "Education",
        "Public",
        "Hotel & Leisure",
        "Industrial and Infrastructure",
      ],
    },
  ];
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 3,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          arrows: false,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };
  return (
    <div>
      <section className="specialization section-bg">
        <Container>
          <h2 className="text-center mt-5">Our Specialization</h2>
          <Slider {...settings} className="projects mt-5 text-center">
            {specializationCards.map((card) => {
              return (
                <div
                  className="card project-single"
                  style={{ width: "18rem", margin: "10px" }}
                >
                  <img
                    src={card.img}
                    className="card-img-top img-fluid"
                    alt="..."
                  />
                  <div id="specialization" className="card-body-specialization">
                    <h5
                      style={{ color: "#5C312F" }}
                      className="card-title text-center mt-3"
                    >
                      <strong>{card.title}</strong>
                    </h5>
                    <p className="card-text text-left mt-2">
                      {card.points.map((point) => {
                        return (
                          <>
                            {/* <img src={Arwimg} /> */}
                            {" • " + point} <br />
                          </>
                        );
                      })}
                    </p>
                  </div>
                </div>
              );
            })}
          </Slider>
        </Container>
      </section>
    </div>
  );
};

export default Specialization;
