export const menu = [{
        label: "Home",
        path: "/",
    },
    {
        label: "About Us",
        path: "/about-us",
    },
    {
        label: "Services",
        path: "/services",
    },
    {
        label: "Our Works",
        path: "/gallery",
    },
    {
        label: "Contact Us",
        path: "/contact-us",
    },
];