import React from "react";
// import { Button, Carousel } from 'react-bootstrap'
import Slider1 from "../assets/images/slider-1.jpg";
import Slider2 from "../assets/images/slider-2.jpg";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Slider = () => {
  return (
    <div className="slider">
      <Carousel
        interval={7000}
        autoPlay="true"
        showArrows
        emulateTouch
        infiniteLoop
        showIndicators="false"
      >
        <div>
          <img className="d-block w-100" src={Slider1} alt="slider1" />
          <div className="carousel-caption">
            <div id="slider-heading">
              Providing you with exceptional <br /> service and quality
              workmanship <br /> across London
            </div>
            {/* <h1>
                Providing you with exceptional <br /> service and quality
                workmanship <br /> across London
              </h1> */}
            <p>Our carpentry services are professional and reliable.</p>
            <a href="/about-us" className="d-none d-sm-block">
              <button type="button" class="btn btn-outline-light">
                KNOW MORE
              </button>
            </a>
          </div>
        </div>
        <div>
          <img className="d-block w-100" src={Slider2} alt="slider2" />
          <div className="carousel-caption">
            {/* <h1>We built for your future</h1> */}
            <div id="slider-heading">We build for your future</div>
            <p>
              Putting in action your remodeling ideas and turning them into a
              beautiful and stylish result.
            </p>
            <a href="/contact-us" className="d-none d-sm-block">
              <button type="button" class="btn btn-outline-light">
                ENQUIRY NOW
              </button>
            </a>
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default Slider;
