import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Col, Row } from 'react-bootstrap'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';

const GetInTouch = () => {
    return (
        <div>
            <section className="touch bg-2">
                <div className="bgc-2">
                    <Container>
                        <Row>
                            <Col md={12} className="my-auto mt-5">
                                <h2 style={{color:"#fff"}}>Are you looking for a carpentry or joinery contractor?</h2>
                                <p>If so, you are in the right place. Check out our projects and contact us if you are interested.</p>
                            </Col>
                            <Col md={12} className="text-center mt-5">
                                <Link to="/contact-us" className="btn btn-primary center">Contact Us</Link>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </div>
    )
}

export default GetInTouch
