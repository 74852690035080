import React, { useState, useRef } from "react";
import { Alert, Col, Row, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Recaptcha from "react-google-invisible-recaptcha";
import { URL } from "../config";
import axios from "axios";
import ltrim from "validator/lib/ltrim";
import rtrim from "validator/lib/rtrim";
import isEmail from "validator/lib/isEmail";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const ContactForm = () => {
  const form = useRef();
  const to_email = "info@devcontractors.co.uk";
  const cc = 'arkayappsseo@gmail.com';
  const title = "Website Inquiry";
  const subject = "Contact Form: Dev Contractors";
  let navigate = useNavigate();
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(null);
  const [fname, setName] = useState(null);
  const [lname, setLName] = useState(null);
  const [mailsubject, setSubject] = useState(null);
  const [email, setEmail] = useState(null);
  const [message, setMessage] = useState(null);
  const template = `<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Dev Contractors</title>
    <style>
        body {
            line-height: 1.4;
            font-family: sans-serif;
            background-color: #f6f6f6;
        }

        p {
            margin: 0;
            margin-bottom: 15px;
            font-size: 14px;
            font-weight: normal;
            font-family: sans-serif;
        }

        hr {
            border: 0;
            border-bottom: 1px solid #6E2A2A;
            margin: 10px 0;
        }

        .logo {
            margin-top: 1rem;
            margin-bottom: 1rem;
            width:200px;
            height:auto;
        }

        .text-center {
            text-align: center !important;
        }

        .content {
            Margin: 0 auto;
            display: block;
            max-width: 580px;
            padding: 0 10px 0 10px;
            box-sizing: border-box;
        }

        .innerwrap {
            box-sizing: border-box;
            padding: 20px 20px 10px 20px;
            background: #f2f2f2;
        }

        .card-header {
            background: #6E2A2A;
            color: #fff;
            text-align: center;
            padding: 20px;
            font-size: 16px;
        }

        .card-header p {
            color: #fff;
            text-align: center;
            font-size: 16px;
            margin-bottom: unset;
        }

        .card-body {
            background: #ffffff;
            border-radius: 3px;
            width: 100%;
            box-sizing: border-box;
            padding: 20px;
            border-bottom: 3px solid #6E2A2A;
        }

        .card-body p strong {
            color: #333;
        }

        .card-body p strong.heading {
            color: #6E2A2A;
        }

        .copyright {
            color: #999999;
            font-size: 12px;
            text-align: center;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
    </style>
</head>

<body>
    <div class="content">
        <div class="text-center">
            <img src="https://devcontractors.co.uk/devcontractor-logo.png" alt="logo" class="logo">
        </div>
        <div class="card-header">
            <img src="https://arkayapps.s3.ap-south-1.amazonaws.com/assets/email.png">
            <p>You Received an Inquiry !</p>
        </div>
        <div class="card-body">
            <p><strong>First Name : </strong>${fname}</p>
            <p><strong>Last Name : </strong>${lname}</p>
            <p><strong>Subject : </strong>${mailsubject}</p>
            <p><strong>Message : </strong>${message}</p>
            <hr>
            <p><strong class="heading">Contact Information :</strong></p>
            <div class="innerwrap">
                <p><strong>Email: </strong>${email}</p>
            </div>
        </div>
        <div class="copyright">
            <p>&copy; 2023 Dev Contractors Pvt Ltd</p>
        </div>
    </div>
</body>

</html>`;

  const mailError = <p>{error}</p>;

  const handleName = (e) => {
    const value = e.target.value;
    setName(value);
    setError(null);
  };
  const handleLName = (e) => {
    const value = e.target.value;
    setLName(value);
    setError(null);
  };
  const handleSubject = (e) => {
    const value = e.target.value;
    setSubject(value);
    setError(null);
  };
  const handleEmail = (e) => {
    var value = e.target.value;
    setEmail(value);
    if (!isEmail(value)) {
      setError(<Alert variant={"danger"}>Please enter a valid email</Alert>);
      setDisabled(true);
    } else {
      setError(null);
      setDisabled(false);
    }
  };
  const handleMessage = (e) => {
    const value = e.target.value;
    setMessage(value);
    setError(null);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // handle form submission
    if (fname === null) {
      setError(<Alert variant={"danger"}>Name Required</Alert>);
    } else if (lname === null) {
      setError(<Alert variant={"danger"}>Last Name Required</Alert>);
    } else if (email === null) {
      setError(<Alert variant={"danger"}>Email Required</Alert>);
    } else if (mailsubject === null) {
      setError(<Alert variant={"danger"}>Subject Required</Alert>);
    } else if (message === null) {
      setError(<Alert variant={"danger"}>Message Required</Alert>);
    } else {
      setDisabled(true);
      const data = {
        title: title,
        to_email: to_email,
        cc: cc,
        template: template,
        subject: subject,
      };
      const headerConfig = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      };

      const url = "https://api.sendmail.adhyaynam.in/send-mail";
      axios
        .post(url, data, headerConfig)
        .then((response) => {
          console.log(response);
          document.getElementById("contact-us").reset();
          setDisabled(false);
          navigate("/thank-you");
        })
        .catch((error) => {
          console.log(error.message);
          if (error.response) {
            console.log(error.response);
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log(error);
          }
          setError(<Alert variant={"danger"}>{error.message}</Alert>);
          setDisabled(false);
        });
    }
  };
  return (
    <div>
      <Form
        ref={form}
        className="contact-form"
        id="contact-us"
        onSubmit={handleSubmit}
      >
        <h2 className="mt-5" style={{ color: "" }}>
          Send An Enquiry
        </h2>
        <Row>
          <Col md={6}>
            <input
              type="text"
              name="user_name"
              required
              className="form-control"
              value={fname}
              minLength="3"
              maxLength="50"
              inputProps={{ minLength: 3, maxLength: 50 }}
              onChange={handleName}
              placeholder="First Name*"
            />
          </Col>
          <Col md={6}>
            <input
              type="text"
              name="lname"
              required
              className="form-control"
              value={lname}
              minLength="3"
              maxLength="50"
              inputProps={{ minLength: 3, maxLength: 50 }}
              onChange={handleLName}
              placeholder="Last Name"
            />
          </Col>
          <Col md={12}>
            <input
              name="email"
              type="email"
              required
              className="form-control"
              value={email}
              minLength="3"
              maxLength="50"
              onChange={handleEmail}
              placeholder="Enter your email*"
            />
          </Col>
          {/* <Col md={6}>
                        <PhoneInput country={'in'} onChange={handleMobile} inputProps={{ minLength: 3, maxLength: 18, required: true }} />
                    </Col> */}
          <Col md={12}>
            <input
              name="subject"
              type="text"
              required
              className="form-control"
              value={mailsubject}
              minLength="3"
              maxLength="50"
              inputProps={{ minLength: 3, maxLength: 50 }}
              onChange={handleSubject}
              placeholder="Subject"
            />
          </Col>
          <Col md={12}>
            <textarea
              name="message"
              message="message"
              rows="4"
              placeholder="Message*"
              className="form-control"
              required
              inputProps={{ minLength: 3, maxLength: 200 }}
              onChange={handleMessage}
            ></textarea>
          </Col>
          <Col md={12}>
            <button
              className="btn-outline-dark"
              value="send"
              type="submit"
              disabled={disabled}
            >
              Send Message
            </button>
            <br />
            <br />
          </Col>
          {mailError}
        </Row>
      </Form>
    </div>
  );
};

export default ContactForm;
