const defaultTitle =
    "Best Carpentry and Joinery Company in London | Dev Contractors Ltd.";
const defaultDescription =
    "We are a Carpentry and Joinery Company supplying quality Carpenters & Joiners to the Construction Industry for 10 years in London, UK.";
const siteUrl = "https://devcontractors.uk/";

export const homepage = {
    title: defaultTitle,
    description: defaultDescription,
    canonical: siteUrl,
};
export const aboutUs = {
    title: "About Dev Contractors | Carpentry & Joinery Contractors in London",
    description: "We supply quality carpenters & joiners to the construction industry. We undertake a wide range of joinery projects & have been successfully working in the industry for 10 years.",
    canonical: siteUrl + "about-us",
};
export const services = {
    title: "Carpentry services in London | Joinery Services in London | Dev Contractors Ltd.",
    description: "Find the best Capenters & Joiners in London at Dev Contractors Ltd. Avail top quality Carpentry & Joinery Services at your doorsteps.",
    canonical: siteUrl + "services",
};
export const projectGallery = {
    title: "Our Work | Dev Contractors Ltd. Project Gallery ",
    description: "Looking for professional Carpenter & Joiner services in London? Look no further than Dev Contractors Ltd. We provide the best quality work for your construction. ",
    canonical: siteUrl + "gallery",
};
export const contactUs = {
    title: "Contact Dev Contractors | Best Carpentry and Joinery Company in London ",
    description: "If you are looking for carpentery and joinery company in London, Contact Dev Contractors for expert carpenters and joiners in London.",
    canonical: siteUrl + "contact-us",
};

export const privacyPolicy = {
    title: "Privacy Policy | Dev Contractors Ltd.",
    description: "Privacy Policy by Dev Contractors. Professional Carpentry and Joinery company with expert carpenters and joiners in London.",
    canonical: siteUrl + "privacy-policy",
};

export const thankYou = {
    title: "Thank You | Dev Contractors",
    description: "Thank You | Dev Contractors",
    canonical: siteUrl + "thank-you",
};