import React, { useState } from "react";
// Library
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Container } from "react-bootstrap";
import Prj_1 from "../assets/images/slider-projects/slider-project1.jpg";
import Prj_2 from "../assets/images/slider-projects/slider-project2.jpg";
import Prj_3 from "../assets/images/slider-projects/slider-project3.jpg";
// import Prj_4 from "../assets/images/slider-projects/slider-project4.jpg";
import Prj_5 from "../assets/images/slider-projects/slider-project5.jpg";
import Prj_6 from "../assets/images/slider-projects/slider-project6.jpg";
import Prj_7 from "../assets/images/slider-projects/slider-project7.jpg";
import Prj_8 from "../assets/images/slider-projects/slider-project8.jpg";
import Prj_9 from "../assets/images/slider-projects/slider-project9.jpg";
import Prj_10 from "../assets/images/slider-projects/slider-project10.jpg";
import Prj_11 from "../assets/images/slider-projects/slider-project11.png";
import Prj_12 from "../assets/images/slider-projects/slider-project12.png";
import Prj_13 from "../assets/images/slider-projects/slider-project13.png";
import Prj_14 from "../assets/images/slider-projects/slider-project14.png";
import Prj_15 from "../assets/images/slider-projects/slider-project15.png";
import Prj_16 from "../assets/images/slider-projects/slider-project16.png";
import Prj_17 from "../assets/images/slider-projects/slider-project17.png";
import Prj_18 from "../assets/images/slider-projects/slider-project18.png";
import Prj_19 from "../assets/images/slider-projects/slider-project19.jpeg";

const SliderProjects = () => {

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    arrows: true,
    slidesToShow: 3,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          arrows: false,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          arrows: false,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div>

      <Slider {...settings} className="projects mt-1">
        <div className="project-single">
          <img src={Prj_1} alt="Specialization" className="img-fluid" />
          <h4>Battersea Power Station</h4>
        </div>
        <div className="project-single">
          <img src={Prj_2} alt="Specialization" className="img-fluid" />
          <h4>Facebook</h4>
        </div>
        <div className="project-single">
          <img src={Prj_3} alt="Specialization" className="img-fluid" />
          <h4>KPMG</h4>
        </div>
    
        <div className="project-single">
          <img src={Prj_5} alt="Specialization" className="img-fluid" />
          <h4>Francis Crick Institute</h4>
        </div>
        <div className="project-single">
          <img src={Prj_6} alt="Specialization" className="img-fluid" />
          <h4>21 Morefield </h4>
        </div>
        <div className="project-single">
          <img src={Prj_7} alt="Specialization" className="img-fluid" />
          <h4>1 Curzon Street</h4>
        </div>
        <div className="project-single">
          <img src={Prj_8} alt="Specialization" className="img-fluid" />
          <h4>Stanmore Hospital </h4>
        </div>
        <div className="project-single">
          <img src={Prj_9} alt="Project" className="img-fluid" />
          <h4>PWC</h4>
        </div>
        <div className="project-single">
          <img src={Prj_10} alt="Project" className="img-fluid" />
          <h4>St Mary Church</h4>
        </div>
         <div className="project-single">
          <img src={Prj_11} alt="Project Img" className="img-fluid" />
        </div>
        <div className="project-single">
          <img src={Prj_12} alt="Project Img" className="img-fluid" />
        </div>
        <div className="project-single">
          <img src={Prj_13} alt="Project Img" className="img-fluid" />
        </div>
        <div className="project-single">
          <img src={Prj_14} alt="Project Img" className="img-fluid" />
        </div>
        <div className="project-single">
          <img src={Prj_15} alt="Project Img" className="img-fluid" />
        </div>
        <div className="project-single">
          <img src={Prj_16} alt="Project Img" className="img-fluid" />
        </div>
        <div className="project-single">
          <img src={Prj_17} alt="Project Img" className="img-fluid" />
        </div>
        <div className="project-single">
          <img src={Prj_18} alt="Project Img" className="img-fluid" />
        </div>  
        <div className="project-single">
          <img src={Prj_19} alt="Project Img" className="img-fluid" />
          <h4>45 Mount Street London</h4>
        </div>                                                                                                                                                       
      </Slider>
    </div>
    
  );
};

export default SliderProjects;
