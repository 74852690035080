// Core
import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
// BootStrap
import { Container, Col, Row } from "react-bootstrap";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import RoomIcon from "@mui/icons-material/Room";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import Logo from "../assets/images/logo/devcontractor-logo.png";
import { LinkedIn } from "@mui/icons-material";

const Footer = () => {
  let url = useLocation().pathname;
  const [urlHomepage, setUrlHomepage] = useState("");
  const [urlServices, setUrlServices] = useState("");
  const [urlContact, setUrlContact] = useState("");
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    navigateTo(url);
    // eslint-disable-next-line
  }, []);

  const navigateTo = (url) => {
    if (url === "/") {
      setUrlHomepage("active");
      setUrlServices("");
      setUrlContact("");
    } else if (url === "/services") {
      setUrlHomepage("");
      setUrlServices("active");
      setUrlContact("");
    } else if (url === "/contact-us") {
      setUrlHomepage("");
      setUrlServices("");
      setUrlContact("active");
    }
    window.scrollTo(0, 0);
  };

  return (
    <div>
      <div id="footer">
        <Container>
          <Row>
            <Col lg={4} md={12}>
              <img src={Logo} alt="Logo" className="logo" />
              <p className="text-justify">
                We are a carpentry and joinery company supplying quality
                carpenters & joiners to the construction industry. We undertake
                a wide range of joinery projects & have been successfully
                working in the industry for 10 years.
              </p>
              <a
                href="https://www.facebook.com/people/Dev-Contractors-Ltd/100092298902000/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FacebookIcon fontSize="large" />
              </a>
              <a
                href="https://www.linkedin.com/company/dev-contractors-ltd"
                target="_blank"
                rel="noopener noreferrer"
              >
                <LinkedIn fontSize="large" />
              </a>
            </Col>
            <Col lg={4} md={12} className="contact">
              <h5>Contact Us</h5>
              <RoomIcon />
              <h6>
                {" "}
                337 Athlon Road,
                <br /> Wembley, England, HAO1EF{" "}
              </h6>
              <LocalPhoneIcon />
              <p>
                <a href="tel:+44 7791092574">+44 7791092574</a>
              </p>
              <EmailIcon />
              <p>
                <a
                  href="mailto:info@devcontractors.co.uk"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  info@devcontractors.co.uk
                </a>
              </p>
            </Col>
            <Col lg={4} md={12}>
              <h5>Useful Links</h5>
              <ul>
                <li>
                  <h6>
                    <Link to="/">Home</Link>
                  </h6>
                </li>
                <li>
                  <h6>
                    <Link to="/about-us">About Us</Link>
                  </h6>
                </li>
                <li>
                  <h6>
                    <Link to="/services">Services</Link>
                  </h6>
                </li>
                <li>
                  <h6>
                    <Link to="/gallery">Our Works</Link>
                  </h6>
                </li>
                <li>
                  <h6>
                    <Link to="/contact-us">Contact Us</Link>
                  </h6>
                </li>
                <li>
                  <h6>
                    <Link to="/privacy-policy">Privacy Policy</Link>
                  </h6>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
        <div className="d-block d-sm-none menu-footer">
          <Row className="p-3">
            <Col className="b-right">
              <Link
                to="/"
                className={urlHomepage}
                onClick={() => navigateTo("/")}
              >
                Home
              </Link>
            </Col>
            <Col className="b-right">
              <Link
                to="/services"
                className={urlServices}
                onClick={() => navigateTo("/services")}
              >
                Service
              </Link>
            </Col>
            <Col>
              <Link
                to="/contact-us"
                className={urlContact}
                onClick={() => navigateTo("/contact-us")}
              >
                Contact Us
              </Link>
            </Col>
          </Row>
        </div>
      </div>
      <div style={{ background: "#2B2B2B" }} className="copyright text-center">
        {/* eslint-disable-next-line */}
        <p>
          &copy; {currentYear} Dev Contractors Ltd All rights reserved. Website
          designed and developed by Arkay Apps.
        </p>
      </div>
      <div className="bottom"></div>
      <CookieConsent
        location="none"
        buttonStyle={{ color: "#fff", background: "#3366ff" }}
        style={{
          bottom: "2%",
          boxShadow: " 0 0 10px 0 #aaaaaa",
          flexDirection: "column",
          alignItems: "center",
          //   maxWidth: "330px",
          width: "100%",
          borderRadius: "10px",
          //   right: "2%",
          left: "unset",
          background: "#fff",
          color: "#000",
        }}
        contentStyle={{
          flex: "1 0 auto",
          textAlign: "justify",
          color: "#4d4d4d",
        }}
        debug={false}
        cookieName="myAwesomeCookieName3"
        expires={365}
        buttonText="Accept"
        buttonClasses="btn-primary-1"
      >
        <span style={{ fontSize: "1.3rem", fontWeight: "500" }}>
          We Use Cookies
        </span>{" "}
        <br /> This site uses cookies to deliver our services and to ensure you
        get best experience. By continuing to use this site, you consent to our
        use of cookies and acknowledge that you have read and understand our{" "}
        <Link to="/privacy-policy">
          <u> Privacy Policy</u>
        </Link>
      </CookieConsent>
      <a
        href="https://wa.me/447791092574"
        class="whatsapp_float"
        target="_blank"
        rel="noopener noreferrer"
      >
        <i class="fa fa-whatsapp whatsapp-icon"></i>
      </a>
    </div>
  );
};

export default Footer;
